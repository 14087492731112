import React from 'react';
import { Link } from 'react-router-dom';

function NavMenuItem({ displayStringText, icon,altText }) {
  return (
    <div className='NavMenuItem' >
      <Link to="/" alt={altText}>
        <div className='icon' style={{ maskImage: `url(${icon})` }}></div>
        <p className='text'>{displayStringText}</p>
      </Link>
    </div>
  );
}

export default NavMenuItem;
