import React from 'react'
import "../styles/CtaButton.css"

function CtaButton({icon, text}) {
  return (
    <div className='ctaButton'>
        <div className='button'>
            <div className='buttonIcon' style={{ maskImage: `url(${icon})` }}></div>
            <p>{text}</p></div>
    </div>
  )
}

export default CtaButton