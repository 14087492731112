import React from 'react'
import Hero from './Hero'
import Showcase from './Showcase'
import Features from './Features'

function Home() {
  return (
    <div>
    <Hero />
    {/*<Features />
    <Showcase />*/}
    <p style={{fontSize: 'small'}}>This Website is currently being maintained. Please come back later.</p>
    </div>
  )
}

export default Home