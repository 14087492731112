import React, { useState } from 'react';
import NavMenuItem from './NavMenuItem'
import "../styles/Navbar.css"
import Logo from "../assets/img/FRXLogoBlue.svg"
import Icon_Mail from "../assets/img/ic_round-email.svg"
import Icon_Calendar from "../assets/img/mdi_calendar.svg"
import Icon_Telephone from "../assets/img/bi_telephone-fill.svg"
import Icon_BurgerMenu from "../assets/img/iconamoon_menu-burger-horizontal-bold.svg"
import Icon_Exit from "../assets/img/maki_cross.svg"
import CtaButton from './CtaButton';


function Navbar() {

  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);

  const handleIconClick = () => {
    setIsBurgerMenuOpen((prev) => !prev);
  };

  return (
    <div className='nav-container'>
        <input type="checkbox" id="menu-toggle" />
        {/*<div className='nav-langSelector'>
          <a onClick={()=>window.alert("select lang DE")}><p>deutsch</p></a>
          <a><p><b>english</b></p></a>
          <a><p><b><span class="inline-text" style={{display: `inline`}}>中文</span></b></p></a>
        </div>*/}
      <div className='nav-main'>
              <div className='leftSide'>
              <div className='logo' style={{ maskImage: `url(${Logo})`}}></div>

              </div>
              <div className='rightSide'>
                  <nav>
                 {/* <NavMenuItem icon={Icon_Calendar} displayStringText="Products and Services" altText="Book an demo appointment"/>
                  <NavMenuItem icon={Icon_Calendar} displayStringText="About Us" altText="Book an demo appointment"/>
                  <NavMenuItem icon={Icon_Telephone} displayStringText="Contact Us"  altText="Call us"/>
                  */}
                    
                    <label for="menu-toggle" class="burger-menu">
                    <div onClick={handleIconClick} className='NavMenuMobile' style={{ maskImage: `url(${isBurgerMenuOpen ? Icon_Exit : Icon_BurgerMenu})` }}></div>
                    </label>
                    


                  </nav>
              </div>
      </div>
      <div className='mobileNavMenu'>
        <div className='mobileNavContents'>
          <CtaButton  text={"Get in contact"} />
          <NavMenuItem icon={Icon_Calendar} displayStringText="Book Demo" altText="Book an demo appointment"/>
          <NavMenuItem icon={Icon_Mail} displayStringText="contact@frxartz.com"  altText="Contact us via E-Mail"/>
          <NavMenuItem icon={Icon_Telephone} displayStringText="+49 176 2123971"  altText="Call us"/>
   
        </div>                
      </div>
    </div>
  )
}

export default Navbar;