import React from 'react'
import "../styles/Hero.css"
import CtaButton from '../components/CtaButton'
import ArrowIcon from '../assets/img/akar-icons_arrow-up.svg'
import HeroImg from "../assets/img/heroImg.png"
import BrandLogoDRK from "../assets/logos/DRK_Logo.svg"
import SchaeferLogo from "../assets/logos/Schaefer-Logo.svg"
import DeplaLogo from "../assets/logos/DEPLA_Logo_mit_Zusatz.png"
import bgVideo from "../assets/videos/heroVideo.mp4" // Assuming you have a video file here

function Hero() {
  return (
    <div className='page-wrapper'>
      {/* Video Background */}
      <video autoPlay muted loop className='video-background'>
        <source src={bgVideo} type='video/mp4' />
        Your browser does not support the video tag.
      </video>

      <div className='hero-wrapper'>
        <div className='hero-container'>
          <div className='hero-headline'>
            <h1>Jump into</h1>
            <h1>virtual reality</h1>
          </div>
          <div className='hero-headline-subline'>
            <h2>Tailored virtual reality experiences</h2>
          </div>
          
          <div className='hero-headline-brands'>
            <div className='brands-headline'>
              <h3> </h3>
            </div>
            <div className='brands-logo'>
              <div className='brand' style={{ backgroundImage: `url(${BrandLogoDRK})` }}></div>
              <div className='brand' style={{ backgroundImage: `url(${SchaeferLogo})` }}></div>
              <div className='brand' style={{ backgroundImage: `url(${DeplaLogo})` }}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
